import requestEco from "@/apis/httpEco";
import { NewsListParams } from "@/typings/news";

export function getNewsList(params: NewsListParams) {
  return requestEco({
    url: `/bulletins/`,
    method: "GET",
    params
  });
}

export function getNewsDetail(id: string) {
  return requestEco({
    url: `/bulletin/${id}/`,
    method: "GET"
  });
}
